import { ProfilePage } from 'views'

const PrivateRoutes = [
  {
    path: '/profil',
    component: ProfilePage
  }
]

export default PrivateRoutes
